function RestaurantTable(props) {
  let data = props.data;
  if(!data || data.length === 0) {
    return (
      <></>
    );
  }
  let headerRow = data[0];
  return (
    <div className="grid justify-center grid-cols-1 gap-4 mx-auto my-4 md:grid-cols-4 ">
      {
        props.data && props.data.slice(1).map(row => {
          return <Item currentRow={row} header={headerRow} />
        })
      }
    </div>
  );
}

function Item(props) {
  let currentRow = props.currentRow;
  let header = props.header;
  return (
    <div className="mx-auto border border-black divide-y divide-black rounded-md w-80 h-fit">
      {
        header.map(
          (label, index) =>
            <div className="flex flex-row divide-x divide-black min-h-[25%]">
              <div className={`w-40 p-2 bg-slate-200 ${index === 0 ? 'rounded-tl-md' : ''} ${index === header.length - 1 ? 'rounded-bl-md' : ''}`}>
                {label}
              </div>
              <div className={`w-40 p-2 bg-slate-200 ${index === 0 ? 'rounded-tr-md' : ''} ${index === header.length - 1 ? 'rounded-br-md' : ''}`}>
                {index < currentRow.length ? currentRow[index] : '-'}
              </div>
            </div>
        )
      }
    </div>
  )
}

export default RestaurantTable;