import axios from 'axios';
import { useEffect, useState } from "react";

import RestaurantTable from "./RestaurantTable";
import loadingImage from '../../images/loading.svg';

function RestaurantProject() {
  var [state, setState] = useState({
    loading: true,
    restaurantData: [],
  });
  const api = "https://faue0gd3dg.execute-api.us-east-1.amazonaws.com/staging";
  useEffect(() =>
  {
    axios.get(api).then(response => {
      console.log(response);
      setState(
        {
          loading: false,
          restaurantData: response.data,
        }
      );
    }).catch(err => {
      console.error(err);
      setState({
        loading: false,
        restaurantData: [],
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-5 font-sans text-4xl text-center text-slate-100">
        Restaurant Project
      </div>
      {
        state.loading ?
          <Loading /> :
          <RestaurantTable data={JSON.parse(state.restaurantData?.body || '')} />
      }
    </>
  );
}

function Loading() {
  return (
    <>
      <img alt="" className="absolute w-5 h-5 m-auto animate-spin inset-2/4" src={loadingImage}/>
    </>
  );
}

export default RestaurantProject;